import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Welcome.module.css";
import logo from "../data/img/missiona-logo-2.png";
import logoDemetra from "../data/img/demetra-logo.png";
import { AiOutlineUser, AiOutlineKey } from "react-icons/ai";
import { CircleSpinner } from "react-spinners-kit";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../services/firebaseConfig";
import { useStateContext } from "../contexts/ContextProvider";

const Welcome = () => {
  const { handlePushNotificationSubscription } = useStateContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);

  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
    setErrorMessage("");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setErrorMessage("");
  };

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      // Perform Firebase password reset email
      await sendPasswordResetEmail(auth, username);
      console.log("Password reset email sent")
    } catch (error) {
      console.log("Login error:", error);
      setErrorMessage("Login failed. Invalid credential.");
    } finally {
      setIsLoading(false)
      setForgotPassword(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      // Perform Firebase login
      await signInWithEmailAndPassword(auth, username, password);

      // Move to home page
      navigate("/home");

      // Register the sw to the notifications service
      await handlePushNotificationSubscription();
    } catch (error) {
      console.log("Login error:", error);
      setErrorMessage("Login failed. Invalid credential.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgottenPassword = (event) => {
    setForgotPassword(!forgotPassword);
  };

  return (
    <div className={styles["page-container"]}>
      <div className={styles["background-image"]}></div>
      <div className={styles["form-container"]}>
        <div className={styles["form-title-container"]}>
          <img src={logo} className={styles["logo"]} alt='logo' />
          <img
            src={logoDemetra}
            className={styles["logo-demetra"]}
            alt='logo'
          />
          <h1>
            DEMETRA <span>CLOUD</span>
          </h1>
        </div>
        {forgotPassword ? (
          <form onSubmit={handleForgotPasswordSubmit} className={styles["login-form"]}>
            <h2>
              Insert your email
            </h2>
            {/* Username input*/}
            <div className={styles["input-container"]}>
              <label htmlFor='username'>
                <AiOutlineUser className={styles["icon"]} />
              </label>
              <input
                type='email'
                id='username'
                placeholder='example@domain.com'
                value={username}
                onChange={handleUsernameChange}
                required
              />
            </div>
            <p
              className={styles["forgot-password"]}
              onClick={handleForgottenPassword}
            >
              Login
            </p>
            {isLoading ? (
              <CircleSpinner size={20} color='#ffc400' />
            ) : (
              <button type='submit'>Submit</button>
            )}
            {errorMessage && (
              <p className={styles["error-message"]}>{errorMessage}</p>
            )}
          </form>
        ) : (
          <form onSubmit={handleSubmit} className={styles["login-form"]}>
            {/* Username input*/}
            <div className={styles["input-container"]}>
              <label htmlFor='username'>
                <AiOutlineUser className={styles["icon"]} />
              </label>
              <input
                type='email'
                id='username'
                placeholder='example@domain.com'
                value={username}
                onChange={handleUsernameChange}
                required
              />
            </div>
            {/* Password input*/}
            <div className={styles["input-container"]}>
              <label htmlFor='password'>
                <AiOutlineKey className={styles["icon"]} />
              </label>
              <input
                type='password'
                id='password'
                placeholder='********'
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <p
              className={styles["forgot-password"]}
              onClick={handleForgottenPassword}
            >
              Forgot password?
            </p>
            {isLoading ? (
              <CircleSpinner size={20} color='#ffc400' />
            ) : (
              <button type='submit'>Login</button>
            )}
            {errorMessage && (
              <p className={styles["error-message"]}>{errorMessage}</p>
            )}
          </form>
        )}
      </div>
    </div>
  );
};

export default Welcome;
